import React from "react"
import Layout from "../components/layout"
import Group from "../components/Group"
import QAItem from "../components/qaItem"
import SEO from "../components/seo"
import ProjectName from "../components/ProjectName"
import { Routing, getTitle, getId } from "../utils/routing"
const RC = Routing.GLOSSARY.sub
const title = Routing.GLOSSARY.title

const Glossary = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title={title} />
      <h1>{title}</h1>
      <p>
        There are a lot of trading terms used which can be difficult to
        understand for new players. Below you will find a comprehensive glossary
        with the most important terms, from game specific words to general
        concepts, that you can refer to whenever you see an unfamiliar word or
        phrase.
      </p>
      <p>
        If you come across a term that isn’t in our trading glossary yet, feel
        free to contact us and we’ll add it along with a clear definition.{" "}
      </p>
      <div className="grid">
        <Group id={getId(RC.A)}>
          <h2>{getTitle(RC.A)}</h2>
          <QAItem>
            <h4>Accumulator</h4>
            <p>
              A bet where you combine multiple inliidual bets on different
              events. All of your bets must win in order for the bet to pay out.
              If just one bet doesn’t win, you lose the entire bet. The
              potential payout is calculated by multiplying the odds of each
              inliidual bet. Betting on a accumulator gives you much higher
              potential winnings compared to betting on each bet inliidually,
              but it also has much higher risk, since losing a single bet means
              losing the entire bet. Also known as Parlay or Combination Bet.
            </p>
          </QAItem>

          <QAItem>
            <h4>American Odds</h4>
            <p>See Moneyline Odds</p>
          </QAItem>
          <QAItem>
            <h4>Action</h4>
            <p>A bet or wager of any kind.</p>
          </QAItem>
          <QAItem>
            <h4>Arbitrage</h4>
            <p>See Sure Bet.</p>
          </QAItem>
          <QAItem>
            <h4>Ante Post</h4>
            <p>
              In the United states more commonly known as Futures. The term
              originates from horse racing, and is Latin for “before the post”.
              It was a bet that was placed before the horses were at the post.
            </p>
          </QAItem>
          <QAItem>
            <h4>Asset/Underlying Asset </h4>
            <p>
              This is the financial instrument on which your contract or spread
              is based. Assets are sometimes called markets. Asset classes
              included sport events, poultical outcomes, forex, stock indices,
              and commodity futures. Inulidual assets are things ulke the
              BTC/USD pair, Gold, or the S&amp;P 500 index.
            </p>
          </QAItem>
          <QAItem>
            <h4>At the Money </h4>
            <p>
              When the underlying (indicative) market price is equal to a
              contract strike price, the contract is “at the money.” At
              expiration, only in-the-money contract get the $1.00 payout, so an
              at-the-money contract will still expire worthless.
            </p>
          </QAItem>
        </Group>
        <Group id={getId(RC.B)}>
          <h2>{getTitle(RC.B)}</h2>
          <QAItem>
            <h4>Bad beat</h4>
            <p>
              Losing a bet under unusual or exceptional circumstances. For
              example if you bet on a huge favorite in a match, and the underdog
              somehow still manages to win. Originates from poker, such as when
              the best hand gets outdrawn against all odds.
            </p>
          </QAItem>
          <QAItem>
            <h4>Bearish and Bullish </h4>
            <p>
              Bearish traders (or traders with a bearish opinion) believe a
              market will go down. Bullish means you believe a market will go
              up. Bearish and bullish are sometimes used to describe markets or
              trends themselves.
            </p>
          </QAItem>
          <QAItem>
            <h4>Bookmaker</h4>
            <p>
              A bookmaker is the person or organization that offers odds on
              events (mostly sports), accepts bets on those events and pay out
              winnings. Also referred to as Sportsbook or Bookie.
            </p>
          </QAItem>
          <QAItem>
            <h4>Bookie</h4>
            <p>See Bookmaker.</p>
          </QAItem>
          <QAItem>
            <h4>Broker </h4>
            <p>
              A broker places trades to the exchange on behalf of the client.
              Some brokers also advise or help clients place trades.{" "}
              <strong>
                <ProjectName />
              </strong>{" "}
              members can place orders directly to the exchange and don’t need
              to use a broker.
            </p>
          </QAItem>
        </Group>
        <Group id={getId(RC.C)}>
          <h2>{getTitle(RC.C)}</h2>
          <QAItem>
            <h4>Combination Bet</h4>
            <p>See Accumulator </p>
          </QAItem>
          <QAItem>
            <h4>Commodities</h4>
            <p>
              Commodities are actual things that are usually consumed and used,
              including metals like gold and silver, grains like corn and
              soybeans, and fuels like crude oil and natural gas. Various
              factors including supply, demand, weather, and political unrest
              can affect their prices. <ProjectName /> will introduce markets on
              these and other commodities.
            </p>
          </QAItem>
          <QAItem>
            <h4>Contract </h4>
            <p>
              Contract are limited risk contracts based on a simple yes/no
              market proposition.{" "}
            </p>
          </QAItem>
          <QAItem>
            <h4>Correct Score</h4>
            <p>A bet where the player predicts the final score of a match.</p>
          </QAItem>
          <QAItem>
            <h4>Closing Line</h4>
            <p>The final odds offered before the game begins.</p>
          </QAItem>
          <QAItem>
            <h4>Chalk</h4>
            <p>See Favorite.</p>
          </QAItem>
          <QAItem>
            <h4>Chalk Player</h4>
            <p>
              A player that wagers almost exclusively on favorites and almost
              never on the underdogs.
            </p>
          </QAItem>
          <QAItem>
            <h4>Currency </h4>
            <p>
              Currencies of advanced economies are exchanged for each other
              based on prices that constantly fluctuate. Currencies are traded
              in pairs and form the world’s largest financial market by far,
              with trillions of dollars exchanged daily. The currency market is
              commonly called forex, short for foreign exchange.
            </p>
          </QAItem>
          <QAItem>
            <h4>Current Price, Underlying Indicative Market Price </h4>
            <p>
              <ProjectName /> offers free, real-time price data. This means all
              <ProjectName />
              underlying indicative markets reflect the actual price being
              traded at that instant.
            </p>
          </QAItem>
        </Group>
        <Group id={getId(RC.D)}>
          <h2>{getTitle(RC.D)}</h2>
          <QAItem>
            <h4>Decimal Odds</h4>
            <p>
              Decimal odds are mostly used in Europe. For example: Chelsea 2.1
              vs Arsenal 1.6. To calculate your potential profit, you multiply
              the odds with the amount you bet, and then remove the original bet
              amount. If you bet $100 on Fnatic, your potential return is 2.1 x
              100 = $210. After which you distract your original bet, so the
              potential profit for this bet is $210 - $100 = $110.
            </p>
          </QAItem>
        </Group>
        <Group id={getId(RC.E)}>
          <h2>{getTitle(RC.E)}</h2>
          <QAItem>
            <h4>Exposure</h4>
            <p>
              The maximum amount of money a sportsbook stands to lose on any
              given event.
            </p>
          </QAItem>
          <QAItem>
            <h4>Expiration, Expiry, Expiration Time, Expiration Date</h4>
            <p>
              The point in time at which the Expiration Value is calculated. For
              most
              <ProjectName />
              contracts, exchange members can leave orders to trade right up
              until this point. However, please note that some Events markets
              may close prior to Expiration.
            </p>
          </QAItem>
          <QAItem>
            <h4>Expiration Value</h4>
            <p>
              The calculated level of the underlying market at Expiration, as
              determined by <ProjectName />
              (except for events where it is determined by the relevant source
              agency).
            </p>
          </QAItem>
          <QAItem>
            <h4>Even Money</h4>
            <p>
              A bet with 1:1 odds. There is no profit to be made as a bettor, if
              you place a wager at even money and win you get only back your
              wager.
            </p>
          </QAItem>
        </Group>
        <Group id={getId(RC.F)}>
          <h2>{getTitle(RC.F)}</h2>
          <QAItem>
            <h4>Futures</h4>
            <p>
              Futures are bets on future events. This can be days but also
              weeks, months or years before the event. While you get offered
              better odds by betting further in advance of an event, the
              drawback is that if your team doesn’t participate for the event,
              you lose your bet as well. Also known as Ante Post.
            </p>
          </QAItem>
          <QAItem>
            <h4>Favorite</h4>
            <p>
              The team with the lowest odds in a match, they are considered to
              be the better team. Also, known as Chalk.
            </p>
          </QAItem>
          <QAItem>
            <h4>Floor and Ceiling Levels</h4>
            <p>
              The lower and upper limits against which the expiration value is
              compared in order to produce the Settlement Value. The Settlement
              Value cannot be below the Floor or above the Ceiling.
            </p>
          </QAItem>
          <QAItem>
            <h4>Floor-Ceiling Range </h4>
            <p>
              The distance between the Floor and Ceiling values. The size of the
              Floor/Ceiling range may vary from time to time according to
              conditions in the underlying market.
            </p>
          </QAItem>
          <QAItem>
            <h4>Forex Definition</h4>
            <p>
              Forex, short for foreign exchange, is the name for the currency
              market and includes the EUR/USD and AUD/JPY.
            </p>
          </QAItem>
          <QAItem>
            <h4>Fractional Odds</h4>
            <p>
              Odds that are mostly used in British bookmakers. For example: 2/4.
              To calculate your potential profit, you multiply the odds of the
              event with the amount you bet.
            </p>
          </QAItem>
        </Group>
        <Group id={getId(RC.H)}>
          <h2>{getTitle(RC.H)}</h2>
          <QAItem>
            <h4>Handicap</h4>
            <p>
              An advantage given by sportsbooks to one team, in order to offer
              more attractive odds. Also known as spread. Used for spread
              betting. For example when New England Patriots plays Los Angeles
              Rams in a Super Bowl match, New England Patriots is a huge
              favourite and the game is expected to be pretty one sided. But if
              the bookmaker handicaps New England Patriots, it becomes a much
              more interesting bet. Lets say New England Patriots get a negative
              handicap of (-3). To calculate the score, you subtract the
              handicap from the final score. If they end up at 17-19, this will
              leave them after the handicap is substracted with 2-3, leading the
              other team to win. This means that in a best-of-three they must
              win the game 17-20 to win the bet. A handicap can also be a
              positive number, lets give Los Angeles Rams (+3). To calculate the
              score, you just add this number to the final score to determine
              the winner. If the final score is 17-19, Los Angeles Rams wins the
              game after adding the handicap for a total of 20-19.
            </p>
          </QAItem>
          <QAItem>
            <h4>High roller</h4>
            <p>A person who bets for high amounts.</p>
          </QAItem>
          <QAItem>
            <h4>Handle</h4>
            <p>
              Commonly used term in the United States. Refers to the total
              amount of money wagered on an event.
            </p>
          </QAItem>
          <QAItem>
            <h4>Hook</h4>
            <p>
              A half-point in spread betting. If a team is favourited by 1.5,
              they say it’s favourited by 1 and a hook. Bookmakers often add the
              half point to av class tie scenarios.
            </p>
          </QAItem>
          <QAItem>
            <h4>Handicap Betting</h4>
            <p>See Spread Betting.</p>
          </QAItem>
        </Group>
        <Group id={getId(RC.I)}>
          <h2>{getTitle(RC.I)}</h2>
          <QAItem>
            <h4>In The Money </h4>
            <p>
              When the underlying (indicative) market price is greater than a
              contract’s strike price, the contract is “in the money.” At
              expiration, only in-the-money contracts get the $1.00 payout, so
              “in the money” refers to the contract being profitable.
            </p>
          </QAItem>
          <QAItem>
            <h4>Index, Stock Indices</h4>
            <p>
              A stock index such as the S&amp;P 500, FTSE, or Nasdaq is a
              weighted average of a selection of stocks from a certain sector of
              industry. It reflects the overall value of that sector.
            </p>
          </QAItem>
          <QAItem>
            <h4>Instrument (Financial Instrument)</h4>
            <p>
              Another term used interchangeably with "asset" and "market", which
              can refer to underlying stock indices, commodities, forex pairs,
              or the Bitcoin index.
            </p>
          </QAItem>
        </Group>
        <Group id={getId(RC.J)}>
          <h2>{getTitle(RC.J)}</h2>
          <QAItem>
            <h4>Juice</h4>
            <p>
              The commission the bookmaker takes on each bet. Also known as Vig.
            </p>
          </QAItem>
        </Group>
        <Group id={getId(RC.M)}>
          <h2>{getTitle(RC.M)}</h2>
          <QAItem>
            <h4>Moneyline Odds</h4>
            <p>
              Moneyline odds, or also called American odds since they are
              commonly used in the United States. A positive number indicates
              the underdog, and the odds tell you how much money you will win on
              a wager of $100. A negative number indicates the favorite, and the
              odds tell you how much you must bet in order to win $100. The
              higher the chance for the favorite team to win, the wider the odds
              for the favorite and the underdog are apart. For example: New York
              Giants -425 vs Dallas Cowboys +420. If you decide to bet on New
              York Giants, who are the favorite, you have to bet $425 to win
              $100. If New York Giants wins the match, you get $100 , for a
              total return of $525 (initial bet of $425 + $100 profit won). If
              you bet on Dallas Cowboys and they win, you get your initial stake
              of $100 back in addition of $420, giving you a total payout of
              $420.
            </p>
          </QAItem>
        </Group>
        <Group id={getId(RC.O)}>
          <h2>{getTitle(RC.O)}</h2>
          <QAItem>
            <h4>Out of the Money</h4>
            <p>
              When the underlying (indicative) market price is less than a
              contract’s strike price, the contract is “out of the money.” At
              expiration, only in-the-money contract get the $1.00 payout, so an
              “out of the money” will expire at zero. Prior to expiration, the
              contract will have a variable value based on how close it is to
              the strike price.
            </p>
          </QAItem>
          <QAItem>
            <h4>Over/Under Bet</h4>
            <p>
              In an Over/Under bet the player must predict if a certain number
              is more or less than the limit set by the bookmaker.
            </p>
          </QAItem>
          <QAItem>
            <h4>Opening Line</h4>
            <p>
              The initial odds offered by the bookmaker when an event gets first
              posted. Once players start betting, the odds adjust accordingly.
            </p>
          </QAItem>
        </Group>
        <Group id={getId(RC.P)}>
          <h2>{getTitle(RC.P)}</h2>
          <QAItem>
            <h4>Parlay</h4>
            <p>Also known as Accumulator or Combination Bet.</p>
          </QAItem>
        </Group>
        <Group id={getId(RC.S)}>
          <h2>{getTitle(RC.S)}</h2>
          <QAItem>
            <h4>Settlement Value</h4>
            <p>
              At expiration, a contract is settled and determined to be in, at,
              or out of the money. The settlement value factors in the floor and
              ceiling levels of spreads to decide what the profit or loss will
              be for that spread contract. Contracts must have a settlement
              value of either 0 or 100. Spreads must have a settlement value no
              lower than their floor level and no higher than their ceiling
              level.
            </p>
          </QAItem>
          <QAItem>
            <h4>Strike Price</h4>
            <p>
              The strike price is the price level you think the market will be
              above or below at expiration. If you think the market price
              (expiration value) will be above the strike, you buy the contract.
              If you think the market will be at or below the strike price, you
              sell the contract. The market's price at expiration is compared to
              the strike price to determine whether your contract has settled
              in, at, or out of the money.
            </p>
          </QAItem>
          <QAItem>
            <h4>Speculative Position Limit</h4>
            <p>
              The maximum aggregate position that any person can hold or control
              in any particular contract class. These are sometimes needed to
              prevent mistakes.
            </p>
          </QAItem>
          <QAItem>
            <h4>Strike Width</h4>
            <p>The distance between strike prices for a particular market.</p>
          </QAItem>
          <QAItem>
            <h4>Sportsbook</h4>
            <p>
              The person or organisation that offers odds on events (mostly
              sports), accepts bets on those events and pay out winnings. Also
              referred to as “bookmaker” or “bookie”.
            </p>
          </QAItem>
          <QAItem>
            <h4>Sure Bet</h4>
            <p>
              A sure bet, also referred to as betting arbitrage, is a set of
              bets on both sides of a game that guarantees a profit for the
              player, regardless the outcome of the match. This can be done by
              placing multiple bets with different bookmakers on the same game.
            </p>
          </QAItem>
          <QAItem>
            <h4>Spread Betting</h4>
            <p>
              Spread betting is a type of bet where the bookmaker adds or
              subtracts (handicaps) an amount of points from the final result to
              determine the winner of an event. This is mostly intended to
              create a more equal number of wagers on either side of an event.
              As most eSport games do not have a final score such as in
              football, but instead rely more on a win/lose scenario, it is not
              so often used yet. Also see Handicap.
            </p>
          </QAItem>
        </Group>
        <Group id={getId(RC.T)}>
          <h2>{getTitle(RC.T)}</h2>
          <QAItem>
            <h4>Totals Bet</h4>
            <p>
              A bet where the player predicts if the total score of a game is
              over or under the total posted by the sportsbook. Also see
              Over/Under Bet.
            </p>
          </QAItem>
          <QAItem>
            <h4>Tick Size, Point Size</h4>
            <p>
              The amount the market price must move for the price to move up or
              down one tick in value. If the tick size is one for a contract,
              then one unit of price movement will change the contract’s value
              by one. Many markets have fractional tick sizes. For example, a
              tick size of 0.01 means that a movement of 0.01 in the price would
              change the profit or loss by one tick.
            </p>
          </QAItem>
          <QAItem>
            <h4>Tick/Point Value</h4>
            <p>
              The dollar value of one tick/point. This is $0.01 for all{" "}
              <ProjectName />
              contracts. Other markets have more complicated tick values, dating
              back to older pricing traditions, such as fractions. Sometimes
              tick size and tick value are used interchangeably, especially when
              both values are one, as in the case of <ProjectName /> contracts.
            </p>
          </QAItem>
          <QAItem>
            <h4>Trading Day</h4>
            <p>
              On <ProjectName />, trading days start with commencement of the
              trading platform and never ends. We operate 24/7/465.
            </p>
          </QAItem>
          <QAItem>
            <h4>Trading Hours</h4>
            <p>
              The times of day during which the contract will be open for
              trading on the exchange. See the specific contract details for
              exact information.
            </p>
          </QAItem>
        </Group>
        <Group id={getId(RC.U)}>
          <h2>{getTitle(RC.U)}</h2>
          <QAItem>
            <h4>Underdog</h4>
            <p>
              The team with the highest odds in a match, they are expected to be
              the worse team.
            </p>
          </QAItem>
          <QAItem>
            <h4>Underlying Market</h4>
            <p>
              The source market that expiration values are based on. For
              example, the US 500 contract is based on the Standard &amp; Poor’s
              500 stock index, known as the S&amp;P 500.
            </p>
          </QAItem>
        </Group>
        <Group id={getId(RC.V)}>
          <h2>{getTitle(RC.V)}</h2>
          <QAItem>
            <h4>Vig</h4>
            <p>See Juice.</p>
          </QAItem>
        </Group>
      </div>
    </Layout>
  )
}

export default Glossary
